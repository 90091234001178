.page{
    /* background-color: olive; */
    background-color: #e4e4e4;
    width: 100%;
    min-height: 1100px;
}

.cont{
    width: 80%;
    margin: 0 auto;
    
    height: 100vh;
    padding: 2rem 0;
}

.cont h2{
    text-align: left;
}


.wrapper{
    display: flex;
    justify-content: left;
    
    
}


.cardCont{
    margin: 2rem 0; 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    
    
}


@media (max-width: 1400px){
    .cardCont{
        margin: 2rem 0; 
        display: grid;
        grid-template-columns: 1fr  1fr;
        grid-gap: 2rem;
    }
}


@media (max-width: 950px){
    .cardCont{
        margin: 2rem 0; 
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 2rem;
       width: 100%;
 
    }
   
    .cont{
        width: 95%;
    }
.page{
    min-height: 2900px;
    padding-right: .5rem;
}
}