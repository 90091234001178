.page{
    width: 100%;
    padding-top: 15%;
}


.cont{
    padding: 1rem 2rem;
    margin-top: 25%;
    width: 60%;
    width: 750px;
    height: 350px;
    margin: 0 auto;
    position: relative;
    /* border: 2px solid blue; */
    

}

.cont:before{
    content: "";
   
    position: absolute;
    height: 450px;
    width: 750px;
    
    padding: 1rem 2rem;
    top:-40px;
    left:0;
    border-left: 3px solid crimson; 
    border-right: 3px solid #000;
    transition: all .4s;
   
    
}

.cont:hover:before{
    transform: rotateY(180deg);
    
}

/* .afterLoad:before{
    transform: rotateY(180deg);
} */

.cont:after{
    content: "";
    position: absolute;
    height: 350px;
    width: 850px;
    padding: 1rem 2rem;
    top: 0;
    left: -40px;
    border-top: 3px solid crimson;
    border-bottom: 3px solid #000;
    transition: all .4s;
    transition-delay: .4s;
}

.cont:hover:after{
    transform: rotateX(180deg);
}

/* .afterLoad:after{
     transform: rotateX(180deg);
} */

h2{
    text-align: center;
    font-size: 28px;
    font-weight: 550;
    color: #333;
    
}

.about{
    line-height: 2rem;
    font-size: 22px;
    text-align: center;
    color: #333;
    letter-spacing: 1.5px;
    
}


@media (max-width: 1350px){
    .cont{
        width: 550px;
        height: 550px;
    }
    .cont:before{
        height: 650px;
        width: 550px;
    }
    .cont:after{
        height: 550px;
        width: 650px;
    }
    .about{
        padding-top: 1rem;
        line-height: 2.8rem;
    }
}

@media (max-width: 1250px){
    .cont{
        width: 350px;
        height: 550px;
    }
    .cont:before{
        height: 650px;
        width: 350px;
    }
    .cont:after{
        height: 550px;
        width: 450px;
    }
    .about{
        font-size: 14px;
        line-height: 1.8rem;
    }
    h2{
        font-size: 20px;
    }
}

@media (max-width: 750px){
    .cont{
        width: 200px;
        height: 650px;
    }
    .cont:before{
        height: 720px;
        width: 200px;
        top: -20px;
    }
    .cont:after{
        height: 650px;
        width: 260px;
        left: -20px;
    }
    .about{
        font-size: 14px;
        line-height: 1.4rem;
    }
    h2{
        font-size: 18px;
    }
}