.page{
    width: 100%;
    padding-top: 5%;
}


.cont{
    padding: 1rem 2rem;
    margin-top: 25%;
    
    width: 90%;
    height: 100vh;
    margin: 0 auto;
   
    /* border: 2px solid blue; */
    position: relative;
    perspective: 100rem;
    
}

.cont h2{
    text-align: center;
    font-size: 28px;
    font-weight: 550;
    color: #333;
}

.rows{
    position: absolute;
   
    /* left: 3rem; */
    left: 10%;
    top: 3rem;
   
    margin: 0 auto;
}
.row{
    display: flex;
    margin-top: 2.5rem;
}

.row1{
    opacity: 0;
    animation: showTech 1.5s .1s forwards;
}

.row2{
    opacity: 0;
    animation: showTech 1.5s .6s forwards;
}

.row3 {
    opacity: 0;
    animation: showTech 1.5s .9s forwards;
}

 .row div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10rem;
    height: 10rem;
    margin-right: .7rem;
    font-size: 26px;
    border: none;
    border-radius: 5px;
}

.invis{
    opacity: 0;
}
img{
    width: 100%;
    height: 100%;
}

/* #nopic{
    background-color: #e5e5e5;
  
} */

@keyframes showTech{
    0% {
        transform: translateY(40rem) rotateY(-20deg)
    }
    100% {
        transform: translateY(0) rotateY(0);
        opacity: 1;
    }

   
}






@media (max-width: 1150px){
    .page{
        padding-right: .3rem;
    }
    .cont{
        padding: 1rem 0;
    }
    .row div{
        width: 7.5rem;
        height: 7.5rem;
        margin-right: .6rem;
        font-size: 18px;
    }

    .cont h2{
        text-align: left;
        margin-left: 1rem;
        font-size: 24px;
    }

    .rows{
        left: 0;
    }

   
}

@media (max-width: 600px){
    .page{
        padding-right: .3rem;
    }

    .cont h2{
        text-align: left;
        margin-left: 2rem;
        font-size: 17px;
    }
    .rows{
        display: flex;
        margin: 2rem;
    }
    .row div{
        width: 3.5rem;
        height: 3.5rem;
        margin-right: .2rem;
        font-size: 18px;
    }
    .row1{
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
        opacity: 0;
        animation: sideWays 1.5s .1s forwards;
    }
    .row2{
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
        opacity: 0;
        animation: sideWays 1.5s .6s forwards;
    }
    
    .row3 {
        display: flex;
        flex-direction: column;
        opacity: 0;
        animation: sideWays 1.5s .9s forwards;
    }


    @keyframes sideWays{
        0% {
            transform: translateX(40rem) rotateX(-20deg)
        }
        100% {
            transform: translateY(0) rotateY(0);
            opacity: 1;
        }
    
       
    }
}