.page{
    /* background-color: olive; */
    width: 100%;
}

.cont{
    width: 80%;
    margin: 0 auto;
    /* background-color: #fff; */
    height: 100vh;
    padding: 4rem 0;
}

.btn{
    margin-right: .5rem;
    padding: 10px 37px;
  color: #333;
  background-color: #fff;
  border: 2px solid #333;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  font-size: 15px;
  transition: all .5s;
}

.btn:hover, .active{
    color: #fff;
    background-color: #333;
}


/* Courses Section  */

.courseCont{
    margin-top: 3rem;
}
.row{
    display: flex;
    height: 5.5rem;
}

.row p{
    font-size: 21px;
    font-weight: 550;
}

hr{
    margin: 0;
    
}

.left, .right{
    width: 50%;
}

.awardLeft{
    width: 45%;
}

.awardRight{
    width: 55%;
}
.right{
    padding-left: 8rem;
}

.inactive{
    background-color: yellow;
}


/* Awards Section  */

.awardsCont{
    margin-top: 6rem;
}

@media (max-width: 950px){
    .row{
       height: 6.5rem;
    }
}


@media (max-width: 8000px){
    .row{
       height: 7.5rem;
    }
}

@media (max-width: 710px){
    .row{
       height: 8.5rem;
    }
    .row p{
        font-size: 16px;
        font-weight: 550;
    }

    .right{
        padding-left: 1.7rem;
    }

    .btn{
        margin-right: .2rem;
        padding: 8px 25px;
    }

    .awardLeft{
       
        word-wrap: break-word;
    }
}
