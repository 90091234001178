
.cont{
    display: flex;
    width: 6rem;
    min-height: 100vh;
    /* height: 100%; */
    background-color: #000;
    color: #fff;
    flex-direction: column;
    align-items: center;
    padding-top: 7rem;
}

.cont p{

   margin-top: 2.7rem;
   cursor: pointer;
   color: #fff;

}

.link{
   

    text-decoration: none;
    color: #fff;
    font-size: 22px;
    font-weight: 400;
}

.hidden{
    opacity: 0;
}

