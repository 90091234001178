.card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 22rem;
   height: 15rem;
    max-width: 22rem;
    max-height: 15rem;
   
    border-radius: 5px;
    background-color: #f9f6f6;
   
    position: relative;
    overflow: hidden;
    margin-bottom: 1.5rem;
}

.title{
    color: #000;
}
.text{
    color: #000;
    font-size: 16px;
    letter-spacing: .1rem;
    text-align: center;
    position: absolute;
    top: -15rem;
    transition: top .3s;
    z-index: 2;
   
}

.card:hover .text{
    top: 2%;
    transition: top .3s .3s;
}

.quickbtn{
    padding: 12px 24px;
    font-size: 19px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    bottom: -5rem;
    position: absolute;
    transition: bottom .3s;
    cursor: pointer;
    background-color: white;
    box-shadow: .3rem .3rem .3rem .1rem;
}

.quickbtn:hover{
    color: #fff;
    background-color: crimson;
    
}
.card:hover .quickbtn{
    bottom: 4%;
    transition: bottom .3s .3s;
}

.image{
    transition: opacity .3s;
}
.card:hover .image{
    opacity: .2;
}

.title{
    /* font-size: 2.5rem; */
    font-weight: 700;
    color: #333;
    text-transform: capitalize;
}

.stack{
    /* font-size: 1.7rem; */
    color: #000;
    font-size: 14px;
}

.video{
 
    
    
}

@media (max-width: 950px){
    .card{
       width: 20rem;
       margin-right: .5rem;
    }
}