.page{
    /* background-color: olive; */
    width: 100%;
}

.cont{
    width: 80%;
    margin: 0 auto;
    /* background-color: #fff; */
    height: 100vh;
    padding: 4rem 0;
}

.cont h2{
    text-align: left;
}

.formWrapper{
    width: 70%;
    height: 45vh;
    background-color: #000;
    color: #fff;
    padding: 5rem;
}

.formCont{
    margin: 0 auto;
    width: 100%;
    /* background-color: red; */
}


/* Form */

.nameField, .subjField{
    height: 2.5rem;
    width: 80%;
    display: block;
    margin-bottom: 15px;
    outline: none;
}

.row{
    width: 80%;
    display: flex;
   
 
}
.emailField, .phoneField{
    width: 38%;
    height: 2.5rem;
    margin-bottom: 15px;
    outline: none;
    
    
}

.emailField{
   
    margin-right: 3%;
}
.phoneField{
   justify-content: flex-end;
   
}

.msgField{
    width: 80%;
    height: 7rem;
    outline: none;
}


.btn{
    display: block;
   margin-top: 15px;
    padding: 10px 37px;
  color: #000;
  background-color: #fff;
  border: 2px solid #333;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  font-size: 15px;
  transition: all .5s;
  outline: none;
  border: none;
}

.btn:hover{
    color: #fff;
    background-color: crimson;
    outline: none;
}


@media (max-width: 650px){
    .emailField, .phoneField{
        height: 2.5rem;
        width: 90%;
        display: block;
        margin-bottom: 15px;
        outline: none;
        
    }

    .nameField, .subjField, .msgField, .btn{ 
        width: 90%;
    }

    .cont{
        width: 95%;
    }

    .formWrapper{
        padding: 5rem 3rem;
    }
}